/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { RiSendPlane2Line } from "react-icons/ri"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import FadeIn from 'react-fade-in';

import Layout from "../components/layout"
import Seo from "../components/seo"

export const pageQuery = graphql`
  query ContactQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Contact = ({ data }) => {
  const { markdownRemark, site } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout className="contact-page" sx={contactStyles.contactPage}>
      <Seo
        title={frontmatter.title}
        description={frontmatter.title + " " + site.siteMetadata.title}
      />
      <FadeIn delay="400">
        <div className="wrapper contact" sx={{ variant: "variants.contactFormColor" }}>
          <h1 align="center" className="h1line" sx={{ variant: "variants.linesColor" }}><span sx={{ variant: "variants.linesBackground" }}>{frontmatter.title}<span sx={{ color: "#D69929" }}>.</span></span></h1>
          {/* <div
          className="description"
          dangerouslySetInnerHTML={{ __html: html }}
        /> */}
          <form
            className="contact-form"
            action="/thanks"
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p>
              <label>
                <input placeholder="Name" type="text" name="name" required />
              </label>
            </p>
            <p>
              <label>
                <input placeholder="Email" type="email" name="email" required />
              </label>
            </p>
            <p>
              <label>
                <input placeholder="Subject" type="text" name="subject" required />
              </label>
            </p>
            <p>
              <label>
                <textarea placeholder="Message" name="message" required></textarea>
              </label>
            </p>
            <p className="text-align-right">
              <button
                className="contact-button"
                type="submit"
              > Send {' '}
                <FontAwesomeIcon icon={faPaperPlane} sx={{
                  variant: "variants: button",
                }} />
              </button>
            </p>
          </form>
        </div>
      </FadeIn>
    </Layout>
  )
}

export default Contact

const contactStyles = {
  contactPage: {
    input: {
      border: "1px solid",
      borderColor: "inputBorder",
      bg: "inputBackground",
      outline: "none",
    },
    textarea: {
      border: "1px solid",
      borderColor: "inputBorder",
      bg: "inputBackground",
      outline: "none",
    },
  },
}
